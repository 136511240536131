<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>
  <div>
    <a-breadcrumb
      style="margin: 16px 0"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Home") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item> {{ t("Pos.Invoice") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div class="btn-container">
    <a-space>
      <a-button class="add-btn" @click="generateInvoiceModal()">
        {{ t("Pos.InvoiceDownload") }}
        <template #icon>
          <SnippetsOutlined />
        </template>
      </a-button>
    </a-space>
  </div>

  <div class="btn-container"></div>
  <div>
    <a-table
      class="table-container"
      :columns="columns"
      :data-source="reactiveData.data"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
          <a-space>
            <a-tooltip>
              <template #title> {{ t("Pos.View") }}</template>
              <a-button type="primary" @click="showDrawerInvoice(record)">
                <template #icon>
                  <EyeOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>{{ t("Pos.Delete") }}</template>
              <a-button type="primary" danger @click="handleDelete(record)">
                <template #icon>
                  <DeleteOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>

        <template v-if="column.key == 'subtotal'">
          {{ addCurrencyCodeForSubTotal(record) }}
        </template>

        <template v-if="column.key == 'total'">
          {{ addCurrencyCodeForTotal(record) }}
        </template>

        <template v-if="column.key == 'vat'">
          {{ addCurrencyCodeForVat(record) }}
        </template>

        <template v-if="column.key == 'FullName'">
          {{ ConcatFullName(record) }}
        </template>
        <template v-if="column.key == 'notes'">
          {{ VerifyEmpltyNote(record) }}
        </template>
      </template>

      <template
        #customFilterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon>
              <SearchOutlined />
            </template>
            {{ t("Pos.Search") }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            {{ t("Pos.Reset") }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
    </a-table>

    <div class="pagination">
      <a-pagination
        class="paginationArrows"
        v-model:current="currentPage"
        :total="form.customLastPage"
        @change="handlePageChange"
        :default-page-size="perPage"
        :showSizeChanger="false"
      />
      <a-select
        class="select-input"
        v-model:value="customPagination"
        placeholder="Order By"
        @change="choosePerPage"
        v-if="form.customLastPage > 10"
      >
        <a-select-option value="10">10 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="20">20 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="50">50 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="100">100 / {{ t("Pos.page") }}</a-select-option>
      </a-select>
    </div>

    <a-drawer
      v-model:open="openAdd"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
      @close="resetAdd"
    >
      <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
          <span>{{ t("Pos.GetInvoice") }}</span>
        </div>
        <div class="drawer-header-all" v-if="locale !== 'ar'">
          <span>{{ t("Pos.GetInvoice") }}</span>
        </div>
      </template>

      <a-form
        ref="addFormRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item name="active">
              <template #label>
                <span class="label-form">{{ t("Pos.Customer") }}</span>
              </template>
              <a-select
                v-model:value="form.active"
                :placeholder="t('Pos.paymentType')"
                class="input-form"
                show-search
                :option-filter-prop="'label'"
              >
                <a-select-option
                  v-for="customer in reactiveData2.data"
                  :key="customer.id"
                  :value="customer.id"
                  :label="customer.first_name + ' ' + customer.last_name"
                >
                  {{ customer.first_name + " " + customer.last_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="startDate">
              <template #label>
                <span class="label-form">{{ t("Pos.InvoiceDate") }}</span>
              </template>
              <a-date-picker
                :placeholder="t('Pos.InvoiceDate')"
                v-model:value="form.startDate"
                class="range-picker"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item name="notes">
              <template #label>
                <span class="label-form">{{ t("Pos.Notes") }}</span>
              </template>
              <a-textarea
                v-model:value="form.notes"
                :rows="4"
                :placeholder="t('Pos.Notes')"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <template #footer>
        <div :class="{ 'is-rtl': textDirection === 'rtl' }">
          <a-space>
            <a-button type="primary" @click="CreateInvoice">{{
              t("Pos.Generate")
            }}</a-button>
            <a-button type="primary" danger @click="hideForm">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
        </div>
      </template>
    </a-drawer>
  </div>
</template>
  <script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRaw,
  computed,
} from "vue";
import type { Rule } from "ant-design-vue/es/form";
import Swal from "sweetalert2";
import {
  EyeOutlined,
  SearchOutlined,
  SnippetsOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import { notification } from "ant-design-vue";
import { storeService } from "@/_services";
import { paymentMethodsService } from "@/_services";
import { invoiceService, customerService, currencyService } from "@/_services";
import { paymentService } from "@/_services";
import dayjs from "dayjs";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { currencyConfig } from "@/_helpers";

export default defineComponent({
  name: "indexStore",
  components: {
    EyeOutlined,
    LbrxLoaderSpinner,
    SearchOutlined,
    SnippetsOutlined,
    DeleteOutlined,
  },
  setup() {
    const modalWidth = ref(720);
    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };

    const customPagination = ref("10");
    let currencyName = "";
    const { locale } = useI18n();
    const openTransferModal = ref<boolean>(false);
    const router = useRouter();
    const navigatePage = (link: string) => {
      router.push(link);
    };

    const searchInput = ref<HTMLInputElement | null>(null);

    const openAdd = ref<boolean>(false);

    const loading = ref(false);
    const isDisabled = ref(false);
    const { t } = useI18n();
    const columns = [
      {
        title: t("Pos.InvoiceNumber"),
        key: "invoice_full_number",
        dataIndex: "invoice_full_number",
      },

      {
        title: t("Pos.destinataire"),
        key: "FullName",
        dataIndex: "FullName",
      },

      {
        title: t("Pos.InvoiceDate"),
        key: "invoice_date",
        dataIndex: "invoice_date",
      },

      {
        title: t("Pos.SubTotal"),
        key: "subtotal",
        dataIndex: "subtotal",
      },

      {
        title: t("Pos.Vat"),
        key: "vat",
        dataIndex: "vat",
      },

      {
        title: t("Pos.Total"),
        key: "total",
        dataIndex: "total",
      },

      {
        title: t("Pos.Notes"),
        key: "notes",
        dataIndex: "notes",
      },

      {
        title: t("Pos.Actions"),
        key: "actions",
        dataIndex: "actions",
      },
    ];

    //form
    const formRef = ref();
    const visible = ref<boolean>(false);

    const reactiveData = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchText: "",
      searchedColumn: "",
    });

    const reactiveDataForCurrency = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchText: "",
      searchedColumn: "",
    });

    const reactiveData2 = reactive({
      data: [] as any[],
      action: "",
    });

    const form = reactive({
      id: "",
      name: "",
      description: "",
      address: "",
      phone: "",
      email: "",
      username: "",
      password: "",
      payment_method_id: "",
      config: "",
      status: "",
      accountNumber: "",
      active: "",
      isDisabled: false,
      currency_code: "",
      currency: "",
      amount_paid: "",
      payment_method: "",
      paidStatus: "",
      notes: "",
      firstname: "",
      lastname: "",
      discount: 0,
      discount_type: "",
      paid: "",
      startDate: "",
      endDate: "",
      customLastPage: 0,
    });

    const resetForm = () => {
      form.id = "";
      form.name = "";
      form.description = "";
      form.address = "";
      form.phone = "";
      form.email = "";
      form.username = "";
      form.payment_method_id = "";
      form.status = "";
      form.password = "";
      form.active = "";
      (form.paidStatus = ""),
        (form.isDisabled = false),
        (form.startDate = ""),
        (form.active = ""),
        (form.notes = "");
    };

    const resetAdd = () => {
      addFormRef.value.resetFields();
    };

    const generateInvoiceModal = () => {
      updateModalWidth();
      resetForm();
      openAdd.value = true;
    };

    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);
    const rules: Record<string, Rule[]> = {
      active: [{ required: false, message: t("Pos.CustomerValidation") }],
      startDate: [{ required: true, message: t("Pos.InvoiceDateValidation") }],
    };

    const formatDate = (dateString: any) => {
      return dayjs(dateString);
    };

    const formatDisplayDate = (dateString: string) => {
      return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
    };

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
      confirm();
      reactiveData.searchText = selectedKeys[0];
      reactiveData.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      customPagination.value = "10";
      clearFilter({ confirm: true });
      reactiveData.searchText = "";
    };

    const open = ref<boolean>(false);

    const showAddDrawer = (state: boolean) => {
      updateModalWidth();
      open.value = state;
      reactiveData.action = "add";
      isDisabled.value = false;
      resetForm();
    };

    const afterOpenChange = (bool: boolean) => {
      //console.log('open', bool);
    };

    const showDrawer = (record: any) => {
      updateModalWidth();
      open.value = true;
      getPaymentMethodById(record.id);
      reactiveData.action = "update";
      //console.log(record.id)
      form.active = record.payment_method_id;
      isDisabled.value = true;
    };

    const showDrawerInvoice = (record: any) => {
      getInvoiceById(record.id);
    };

    const afterOpenAddChange = (bool: boolean) => {
      //console.log('open', bool);
    };

    const handlePageChange = (newPage: any) => {
      currentPage.value = newPage;
      loading.value = true;

      //console.log('per page', perPage.value);
      //console.log('page', currentPage.value)

      invoiceService
        .getAllWithPagination(currentPage.value, customPagination.value)
        .then((response) => {
          //console.log('data', response.data)
          reactiveData.data = response.data;
          form.customLastPage = response.meta.last_page * 10;
        })
        .catch((error) => {
          //console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#bfffca8a",
          color: "white",
        },
      });
    };

    /*   const getStore = () => {
              storeService.getAll()
                  .then((res) => {
                      reactiveData.data = res.data;
                  })
                  .catch((error: any) => {
                      console.error('Error fetching Stores:', error);
                  });
          }*/

    const choosePerPage = () => {
      currentPage.value = 1;
      // currentPage.value = newPage;
      loading.value = true;
      //console.log('per page', perPage.value);
      //console.log('page', currentPage.value)
      invoiceService
        .getAllWithPagination(currentPage.value, customPagination.value)
        .then((response) => {
          //console.log('data', response.data)
          reactiveData.data = response.data;
          form.customLastPage = response.meta.last_page * 10;

          console.log("lastPage", form.customLastPage);
        })
        .catch((error) => {
          //console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getInvoices = () => {
      loading.value = true;
      invoiceService
        .getAllWithPagination(currentPage.value, perPage.value)
        .then((res) => {
          reactiveData.data = res.data;
          reactiveData.totalPages = res.meta.total;
          form.customLastPage = res.meta.last_page * 10;
          //console.log(res.data)
        })
        .catch((error) => {
          //console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const deletePaymentMethod = (id: string) => {
      paymentService
        .delete(id)
        .then((res) => {
          reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
          showSuccessNotification("Payment method deleted successfully");
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => {
          //console.log('finish')
        });
    };

    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });
    const getPaymentMethodById = (id: string) => {
      paymentService
        .show(id)
        .then((res) => {
          // form.user_name = res.data.user.name;
          //form.sale_name = res.data.sale.name;
          //form.currency = res.data.currency.name;
          //form.payment_method = res.data.payment.name;

          //form.em   ail = res.data.email;

          form.amount_paid = res.data.sale.total + " " + res.data.currency.code;
          form.paid = res.data.sale.status;
          form.notes = res.data.notes;
          form.currency = res.data.currency.name;
          form.discount = res.data.sale.discount_value;
          form.discount_type = res.data.sale.discount_type;
          form.payment_method = res.data.payment_method.name;
        })
        .catch((error) => {
          //console.error('Error fetching categories:', error);
        });
    };

    const addFormRef = ref();

    const CreateInvoice = () => {
      addFormRef.value
        .validate()
        .then(() => {
          submitFormInvoice();
        })
        .catch((error: any) => {
          console.log("error generating invoice", error);
        });
    };

    const submitFormInvoice = () => {
      console.log("submit invoice !!!")
      const userString = localStorage.getItem("user");
      if (userString === null) {
        return; // Exit early if user data is not available
      }

      const user = JSON.parse(userString);
      const userId = user.id;

      const payload = {
        customer_id: form.active,
        user_id: userId,
        //sales: form.sale_id,
        invoice_date: formatToStringDate(form.startDate),
        sales_date: formatToStringDate(form.startDate),
        notes: form.notes,
      };

      loading.value = true;
      invoiceService
        .add(payload)
        .then((res) => {
          //reactiveData.data.push(res.data);

          return invoiceService.generate(res.data.id);
        })
        .then((blobData) => {
          const blobUrl = URL.createObjectURL(blobData);
          window.open(blobUrl, "_blank");
          loading.value = false;
          openAdd.value = false;
          showSuccessNotification(t("Pos.InvoiceCreated"));
        })
        .catch((err) => {
          console.error('Error adding invoice:', err);
        })
        .finally(() => {
          showAddDrawer(false);
          loading.value = false;
        });
    };

    const getInvoiceById = (id: string) => {
      loading.value = true;
      invoiceService
        .generate(id)
        .then((blobData) => {
          const blobUrl = URL.createObjectURL(blobData);
          window.open(blobUrl, "_blank");
          loading.value = false;
          showSuccessNotification(t("Pos.InvoiceCreated"));
        })
        .catch((err) => {
          //console.error('Error adding invoice:', err);
        })
        .finally(() => {
          showAddDrawer(false);
          loading.value = false;
        });
    };

    const addStore = () => {
      formRef.value.validate().then(() => {
        let payload = {
          name: form.name,
          description: form.description,
          address: form.address,
          phone: form.phone,
          email: form.email,
          username: form.username,
          password: form.password,
        };
        storeService
          .create(payload)
          .then((res) => {
            reactiveData.data.push(res.store);
          })
          .catch((err) => {
            //console.log(err)
          })
          .finally(() => {
            open.value = false;
          });
      });
    };

    const closePopup = () => {
      open.value = false;
    };

    const addPaymentMethod = () => {
      formRef.value.validate().then(() => {
        let payload = {
          payment_method_id: form.active,
          status: form.status,
          config: "null",
        };
        paymentMethodsService
          .create(payload)
          .then((res) => {
            reactiveData.data.push(res.data);
          })
          .catch((err) => {
            //console.log(err);
          })
          .finally(() => {
            open.value = false;
            location.reload(); // Refresh the page
          });
      });
    };

    const updatePaymentMethod = (id: any) => {
      let payload = {
        payment_method_id: 3,
        config: {},
        status: "active",
      };
      paymentMethodsService
        .update(id, payload)
        .then((res) => {
          reactiveData.data = reactiveData.data.map((el) =>
            el.id !== id ? el : res.data
          );
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => {
          // resetAdd();
          open.value = false;
          location.reload();
        });
    };

    const getAllCustomers = () => {
      loading.value = true;

      customerService
        .getAll(currentPage.value, perPage.value)
        .then((res) => {
          reactiveData2.data = res.data;
        })
        .catch((error) => {
          //console.log("error api : get get customers", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const deleteStore = (id: string) => {
      storeService
        .delete(id)
        .then((res) => {
          reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
          showSuccessNotification("Store deleted successfully");
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => {
          //console.log('finish')
        });
    };

    const hideForm = (bool: boolean) => {
      openAdd.value = false;
      resetAdd();
    };

    const getallCurrency = () => {
      loading.value = true;
      currencyService
        .getAll(currentPage.value, perPage.value)
        .then((res) => {
          const defaultCurrencies = res.data.filter(
            (item: any) => item.is_default === 1
          );
          currencyName = defaultCurrencies.map(
            (item: any) => item.currency.code
          );
          reactiveDataForCurrency.data = defaultCurrencies;
          console.log(reactiveDataForCurrency.data);
          console.log("currencyName", currencyName);
        })
        .catch((error) => {
          //console.log("error api : get get customers", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const addCurrencyCodeForSubTotal = (dateString: any) => {
      if (dateString && dateString.subtotal) {
        const currenciesString = localStorage.getItem("currencies");
        if (currenciesString === null) {
          return;
        }
        const currencies = JSON.parse(currenciesString);
        const currencyCode = currencies.code;
        const decimalPrecision = 3;
        return currencyConfig.formatAmount(
          dateString.subtotal,
          currencyCode,
          decimalPrecision
        );
      }
    };

    const addCurrencyCodeForTotal = (dateString: any) => {
      if (dateString && dateString.total) {
        const currenciesString = localStorage.getItem("currencies");
        if (currenciesString === null) {
          return;
        }
        const currencies = JSON.parse(currenciesString);
        const currencyCode = currencies.code;
        const decimalPrecision = 3;
        return currencyConfig.formatAmount(
          dateString.total,
          currencyCode,
          decimalPrecision
        );
      }
    };

    const addCurrencyCodeForVat = (dateString: any) => {
      if (dateString && dateString.vat) {
        const currenciesString = localStorage.getItem("currencies");
        if (currenciesString === null) {
          return;
        }
        const currencies = JSON.parse(currenciesString);
        const currencyCode = currencies.code;
        const decimalPrecision = 3;
        return currencyConfig.formatAmount(
          dateString.vat,
          currencyCode,
          decimalPrecision
        );
      }
    };

    const ConcatFullName = (dateString: any) => {
      if (dateString && dateString.customer) {
        return (
          dateString.customer.first_name + " " + dateString.customer.last_name
        );
      } else {
        return "";
      }
    };

    const VerifyEmpltyNote = (dateString: any) => {
      if (dateString && dateString.notes) {
        return dateString.notes;
      } else {
        return "---";
      }
    };

    const formatToStringDate = (date: any) => {
      if (!date || !date.$d) {
        return "";
      }

      const year = date.$y;
      const month = date.$M + 1;
      const day = date.$D < 10 ? "0" + date.$D : date.$D;

      const stringDate = `${year}-${month < 10 ? "0" : ""}${month}-${day}`;
      return stringDate;
    };
    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: "Delete Payment",
        text: "Are you sure you want to delte this payment",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: "Delete",
        cancelButtonColor: "#d33",
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deletePaymentMethod(record.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          //console.log("cancel delete ");
        }
      });
    };

    //delete invoice
    const handleDelete = (record: any) => {
      Swal.fire({
        title: t("Pos.deleteInvoiceTitle"),
        text: t("Pos.deleteInvoiceText"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t("Pos.Delete"),
        cancelButtonColor: "#d33",
        cancelButtonText: t("Pos.Cancel"),
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          invoiceService
            .deleteInvoice(record.id)
            .then((response) => {
              showSuccessNotification(t("Pos.invoiceDeleteSuccess"));
              getInvoices();
            })
            .catch((error) => {
              console.log("error api : delete products", error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      });
    };

    onMounted(() => {
      getallCurrency();
      //getStore();
      getAllCustomers();
      getInvoices();
      searchInput.value = document.querySelector(
        ".ant-table-filter-dropdown input"
      );
    });

    return {
      reactiveDataForCurrency,
      ConcatFullName,
      locale,
      hideForm,
      openAdd,
      formatToStringDate,
      generateInvoiceModal,
      afterOpenChange,
      handleReset,
      handleSearch,
      loading,
      resetAdd,
      navigatePage,
      perPage,
      currentPage,
      handlePageChange,
      t,
      columns,
      formRef,
      form,
      reactiveData,
      reactiveData2,
      showAddDrawer,
      showDrawer,
      showDrawerInvoice,
      open,
      formatDate,
      addStore,
      showDeletePopup,
      visible,
      addPaymentMethod,
      updatePaymentMethod,
      addFormRef,
      isDisabled,
      closePopup,
      rules,
      openTransferModal,
      CreateInvoice,
      choosePerPage,
      textDirection,
      currencyName,
      addCurrencyCodeForSubTotal,
      addCurrencyCodeForTotal,
      addCurrencyCodeForVat,
      customPagination,
      VerifyEmpltyNote,
      modalWidth,
      handleDelete,
    };
  },
});
</script>
  
  <style scoped>
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.range-picker {
  width: 100%;
}
.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.table-container {
  overflow-x: auto;
}

.select-input {
  width: 8%;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}

.test-btn {
  color: #bfffca8a;
}
.input-form {
  color: black;
  background-color: white;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

/* loader container */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb-icon {
  cursor: pointer;
}

.is-rtl {
  direction: rtl;
}

.drawer-header-ar {
  padding-right: 2%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.drawer-header-all {
  display: flex;
  justify-content: start;
  align-items: center;
}

.paginationArrows {
  direction: ltr;
}

@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
    margin-top: 10px;
  }
}
</style>