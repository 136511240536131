import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const invoiceService = {
   
    add,
    generate,
    getAll,
    getAllWithPagination,
    deleteInvoice,
    

};



function add(payload:object ){
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.invoices.create}`, requestOptions)
        .then(
            handleResponse
        );
}


function generate(id: string) {
    const requestOptions = {
        method: "GET",
        credentials: 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.invoices.generate.replace(':id', id)}`, requestOptions)
        .then(handleResponse2);
}


function getAll() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }, 
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.invoices.getAll}?pagination=off`, requestOptions)
        .then(
            handleResponse
        );
}

function getAllWithPagination(currentPage:any , perPage:any) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }, 
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.invoices.getAll}?per_page=${perPage}&page=${currentPage}`, requestOptions).then(handleResponse);
}

function deleteInvoice(id:number){
    const requestOptions={
        method:"DELETE",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,

    }

    return fetch(`${ApiConfigs.base_url+ApiConfigs.invoices.delete}${id}`, requestOptions)
        .then(handleResponse);
}


function handleResponse2(response: Response) {
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.blob(); // Change to response.blob() to get blob data
}


function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
