import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";


export const paymentService = {
    getAll,
    filterWithPagination,
    show,
    create,
    delete: _delete,
    update,
    filterWithPaginationWithoutMethod
};

function getAll(currentPage:number , perPage:number) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.payment.getAll}?per_page=${perPage}&page=${currentPage}`, requestOptions)
        .then(
            handleResponse
        );
}


function filterWithPagination(payment_method_name : any , page:any , per_page:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.payment.getAll}?payment_method_name=${payment_method_name}&page=${page}&per_page=${per_page}`, requestOptions)
        .then(
            handleResponse
        );
}


function filterWithPaginationWithoutMethod( page:any , per_page:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.payment.getAll}?page=${page}&per_page=${per_page}`, requestOptions)
        .then(
            handleResponse
        );
}


function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.payment.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function create(payload:object ){
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.payment.create}`, requestOptions)
        .then(
            handleResponse
        );
}

function _delete(id: string){
    const requestOptions={
        method:"DELETE",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },

    }

    return fetch(`${ApiConfigs.base_url+ApiConfigs.payment.delete.replace(':id', id)}`, requestOptions)
        .then(handleResponse);
}

function update(id: string, payload: object){
    console.log('ertert', id)
    const requestOptions={
        method:"PUT",
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(payload)
    }

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.payment.update.replace(':id', id)}`, requestOptions)
    .then(handleResponse);
}


function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
