import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const productService = {
    getProducts,
    getProductsByName,
    addProduct,
    deleteProduct,
    updateProduct,
    show,
    getAll,
    getProductsForLoyalty,
    addProductVarinat,
    getProductsVarinats,
    showProductVariant,
    updateProductVariant,
    deleteProductVariant,
    addProductStock,
    getProductsStock,
    updateProductStock,
    showProductStock,
    deleteProductStock,
    createProduct,
    update,
    getProductsByNameWithPagination







};

function getAll(){
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials, 
    };
    return fetch(`${ApiConfigs.base_url+ ApiConfigs.product.getAll}?pagination=off`, requestOptions)
        .then(handleResponse);
}


function getProductsByName(value: string) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.product.getAll}?key=name&value=${value}&pagination=off`, requestOptions)
        .then(handleResponse);
}

function getProductsByNameWithPagination(value: string, page:any, per_page:any) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.product.getAll}?key=name&value=${value}&page=${page}&per_page=${per_page}`, requestOptions)
        .then(handleResponse);
}

function getProducts(currentPage: number, perPage: number) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.product.getAll}?per_page=${perPage}&page=${currentPage}`, requestOptions)
        .then(
            handleResponse
        );
}


function getProductsForLoyalty(key: string, value: string , pagination: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.product.getAll}?key=${key}&value=${value} &pagination=${pagination}`, requestOptions)
        .then(
            handleResponse
        );
}


function addProduct(payload:object ){
    
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json","Accept": "application/json" },
        body: JSON.stringify(payload),
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.product.add}`, requestOptions)
        .then(
            handleResponse
        );
}

function createProduct(name:string, description:string,price:string,
    purchase_price:string,category_id:string,manufacturer_id:string,
    supplier_id:string,has_ingredients:any,vat_id:string,
    barcode:string,code_ean:string,ingredients:any,
    unit:string, display_rank:string,productionPoint:string,refundable:any, photo:any,fixed_tax:any, stock:any){

    const formData = new FormData();

    formData.append('name',name);
    if(description!==""){
        formData.append('description',description);
    }
    
    formData.append('unit_price',price);
    formData.append('purchase_price',purchase_price);
    formData.append('category_id',category_id);
    formData.append('manufacturer_id',manufacturer_id);
    formData.append('supplier_id',supplier_id);
    formData.append('has_ingredients',has_ingredients);
    formData.append('vat_id',vat_id);
    formData.append('barcode',barcode);
    formData.append('code_ean',code_ean);
    formData.append('ingredients',ingredients);
    formData.append('unit',unit); 
    formData.append('display_rank',display_rank);
    formData.append('production_point_id', productionPoint);
    formData.append('refundable', refundable);
    formData.append('fixed_tax', fixed_tax);
    formData.append('is_stockable', stock);
    if(photo!==null){
        formData.append('photo',photo );
    }
    

   
    

    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Accept": "application/json" },
        body: formData
    };
    
    return fetch(`${ApiConfigs.base_url + ApiConfigs.product.add}`, requestOptions)
        .then(
            handleResponse
        );

    
}


function update(id:string,name:string, description:string,price:string,
    purchase_price:string,category_id:string,manufacturer_id:string,
    supplier_id:string,has_ingredients:any,vat_id:string,
    barcode:string,code_ean:string,ingredients:any,
    unit:string, display_rank:string,productionPoint:string,refundable:any, photo:any, fixed_tax:any, stockable:any      ){

    const formData = new FormData();

    formData.append('name',name);
    formData.append('description',description);
    formData.append('unit_price',price);
    formData.append('purchase_price',purchase_price);
    formData.append('category_id',category_id);
    formData.append('manufacturer_id',manufacturer_id);
    formData.append('supplier_id',supplier_id);
    formData.append('has_ingredients',has_ingredients);
    formData.append('vat_id',vat_id);
    formData.append('barcode',barcode);
    formData.append('code_ean',code_ean);
    formData.append('ingredients',ingredients);
    formData.append('unit',unit); 
    formData.append('display_rank',display_rank);
    formData.append('production_point_id', productionPoint);
    formData.append('refundable', refundable);
    formData.append('fixed_tax', fixed_tax);
    formData.append('is_stockable', stockable)
    if(photo!==null){
        formData.append('photo',photo );
    }
    

   
    

    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Accept": "application/json" },
        body: formData
    };
    
    return fetch(`${ApiConfigs.base_url + ApiConfigs.product.update.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );

    
}


function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.product.getById.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function deleteProduct(id:number){
    const requestOptions={
        method:"DELETE",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,

    }

    return fetch(`${ApiConfigs.base_url+ApiConfigs.product.delete}${id}`, requestOptions)
        .then(handleResponse);
}

function updateProduct(id:number, payload:object){
    const requestOptions={
        method:"PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
        credentials : 'include' as RequestCredentials,
    }

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.product.update}${id}`, requestOptions)
    .then(handleResponse);
}

//product variant 

function addProductVarinat(payload:object, id:string ){
    
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.productVarinat.add.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function getProductsVarinats(id:string,currentPage: number, perPage: number) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.productVarinat.getAll.replace(':id', id)}?page=${currentPage}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function updateProductVariant(id:string,variant:string, payload:object){
    const requestOptions={
        method:"PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
        credentials : 'include' as RequestCredentials,
    }

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.productVarinat.update.replace(':id', id).replace(':variant', variant)}${id}`, requestOptions)
    .then(handleResponse);
}

function showProductVariant(id: string, variant:string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.productVarinat.show.replace(':id', id).replace(':variant', variant)}`, requestOptions)
        .then(
            handleResponse
        );
}

function deleteProductVariant(id:string, variant:string){
    const requestOptions={
        method:"DELETE",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,

    }

    return fetch(`${ApiConfigs.base_url+ApiConfigs.productVarinat.delete.replace(':id', id).replace(':variant', variant)}`, requestOptions)
        .then(handleResponse);
}

//product stock 

function addProductStock(payload:object, id:string ){
    
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.productStock.add.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function getProductsStock(id:string,currentPage: number, perPage: number) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.productStock.getAll.replace(':id', id)}?page=${currentPage}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function updateProductStock(id:string,variant:string, payload:object){
    const requestOptions={
        method:"PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
        credentials : 'include' as RequestCredentials,
    }

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.productStock.update.replace(':id', id).replace(':variant', variant)}${id}`, requestOptions)
    .then(handleResponse);
}

function showProductStock(id: string, variant:string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.productStock.show.replace(':id', id).replace(':variant', variant)}`, requestOptions)
        .then(
            handleResponse
        );
}

function deleteProductStock(id:string, variant:string){
    const requestOptions={
        method:"DELETE",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,

    }

    return fetch(`${ApiConfigs.base_url+ApiConfigs.productStock.delete.replace(':id', id).replace(':variant', variant)}`, requestOptions)
        .then(handleResponse);
}






function handleResponse(response: any) {

    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem("user");
            localStorage.removeItem("store");
            location.reload();
            authService.logout();
        }
        const error = (response && response.message) || response.status || response.statusText;
        return Promise.reject(error);
    }
    return response.json().then((rep: any) => {
        return rep;
    });
    
    // return response.text().then((text: string) => {
    //     const data = text && JSON.parse(text);
        
    //     if (!response.ok) {
    //         if (response.status === 401) {
    //             // auto logout if 401 response returned from api
    //             localStorage.removeItem('user');
    //             localStorage.removeItem("store");

    //             location.reload();
    //         }
    //         const error = (data && data.message) || response.status || response.statusText;
    //         return Promise.reject(error);
    //     }

    //     return data;
    // });
}
