<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>
  <div>
    <a-breadcrumb
      style="margin: 16px 0"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Home") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item> {{ t("Pos.Payments") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div>
    <a-table
      class="table-container"
      :columns="columns"
      :data-source="reactiveData.data"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
          <a-space>
            <a-tooltip>
              <template #title> {{ t("Pos.View") }}</template>
              <a-button type="primary" @click="showDrawer(record)">
                <template #icon>
                  <EyeOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>

        <template v-if="column.key == 'payment_method'">
          <template v-if="record.unit === 'Cash'">{{
            t("Pos.Milligrams")
          }}</template>
          <template v-if="record.unit === 'Credit Card'">{{
            t("Pos.Pieces")
          }}</template>
          <template v-if="record.unit === 'Ticket'">{{
            t("Pos.Milliliter")
          }}</template>
        </template>

        <template v-if="column.key == 'id'">
          {{ formatID(record) }}
        </template>
        <template v-if="column.key == 'currency_code'">
          {{ formatcurrency_code(record) }}
        </template>
        <template v-if="column.key == 'amount_paid'">
          {{ addCodeToAmount(record) }}
        </template>
        <template v-if="column.key == 'payment_method'">
          <span v-if="record.payment_method != null">{{
            formatpayment_method(record)
          }}</span>
          <span v-else> --- </span>
        </template>
        <template v-if="column.key == 'notes'">
          {{ FormatEmptyNote(record) }}
        </template>
        <template v-if="column.key == 'paidStatus'">
          <div v-if="record.sale?.is_paid">
            <a-tag
              :bordered="false"
              color="success"
              v-if="record.sale.is_paid == '1'"
            >
              <template #icon>
                <check-circle-outlined />
              </template>
              {{ t("Pos.paid") }}
            </a-tag>
            <a-tag
              :bordered="false"
              color="error"
              v-if="record.sale.is_paid == '0'"
            >
              <template #icon>
                <check-circle-outlined />
              </template>
              {{ t("Pos.unpaid") }}
            </a-tag>
          </div>
        </template>

        <template v-if="column.key == 'created_at'">
          <span v-if="record.sale != null">{{
            formatSaleDate(record.sale.created_at)
          }}</span>
        </template>
      </template>

      <template
        #customFilterDropdown="{ selectedKeys, confirm, clearFilters, column }"
      >
        <div style="padding: 8px">
          <template v-if="column.key === 'payment_method'">
            <div class="search-btn-radio">
              <a-radio-group
                v-model:value="value"
                @change="selectedType"
                class="search-radio-grp"
              >
                <a-col>
                  <a-radio value="Cash" class="radio-item">Cash</a-radio>
                </a-col>
                <a-col>
                  <a-radio value="Credit Card	" class="radio-item"
                    >Credit Card</a-radio
                  >
                </a-col>
                <a-col>
                  <a-radio value="Ticket" class="radio-item">Ticket</a-radio>
                </a-col>
              </a-radio-group>
            </div>
          </template>
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            {{ t("Pos.Search") }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            {{ t("Pos.Reset") }}
          </a-button>
        </div>
      </template>
      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
    </a-table>

    <div class="pagination">
      <a-pagination
        class="paginationArrows"
        v-model:current="currentPage"
        :total="form.customLastPage"
        @change="handlePageChange"
        :default-page-size="perPage"
        :showSizeChanger="false"
      />
      <a-select
        class="select-input"
        v-model:value="customPagination"
        @change="choosePerPage"
        v-if="form.customLastPage > 10"
      >
        <a-select-option value="10">10 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="20">20 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="50">50 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="100">100 / {{ t("Pos.page") }}</a-select-option>
      </a-select>
    </div>
    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
    >
      <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
          <span>{{ t("Pos.PaymentDetails") }}</span>
        </div>
        <div class="drawer-header-all" v-if="locale !== 'ar'">
          <span>{{ t("Pos.PaymentDetails") }}</span>
        </div>
      </template>
      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <template #header>
          <div class="custom-header">{{ t("Pos.GeneralInformations") }}</div>
        </template>

        <a-row :gutter="16">
          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="amountPaid">
                {{ t("Pos.PaidAmount") }} :</span
              >{{ form.amount_paid }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="paymentMethod">
                {{ t("Pos.PaymentMethod") }} :</span
              >
              {{ form.payment_method }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="currency">
                {{ t("Pos.currency") }} :</span
              >
              {{ form.currency }}
            </p>
          </a-col>
          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="discountValue">
                {{ t("Pos.discount") }} :</span
              >
              {{ form.discount }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title">{{ t("Pos.discountType") }} : </span>
              <template v-if="form.discount_type === 'percentage'">
                {{ t("Pos.Percentage") }}
              </template>
              <template v-if="form.discount_type === 'cash'">
                {{ t("Pos.Cash") }}
              </template>
            </p>
          </a-col>
          <a-col :span="12">
            <p>
              <span class="info-title">{{ t("Pos.Status") }} :</span>
              <a-tag
                :bordered="false"
                color="success"
                v-if="form.paid === 'paid'"
              >
                <template #icon>
                  <check-circle-outlined />
                </template>
                {{ t("Pos.paid") }}
              </a-tag>
              <a-tag
                :bordered="false"
                color="error"
                v-if="form.paid === 'unpaid'"
              >
                <template #icon>
                  <check-circle-outlined />
                </template>
                {{ t("Pos.unpaid") }}
              </a-tag>
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="notes">
                {{ t("Pos.Notes") }} :</span
              >
              {{ form.notes }}
            </p>
          </a-col>
        </a-row>
      </a-form>

      <template #footer> </template>
    </a-drawer>
  </div>
</template>
<script  lang="ts">
import {
  defineComponent,
 
} from "vue";
export default defineComponent({
  name: "indexStore",
});
</script>
<script setup lang="ts">
import {
  ref,
  reactive,
  onMounted,
  toRaw,
  computed,
  onBeforeUnmount,
} from "vue";
import type { Rule } from "ant-design-vue/es/form";
import Swal from "sweetalert2";
import {
  EyeOutlined,
  SearchOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import { notification } from "ant-design-vue";
import { storeService } from "@/_services";
import { paymentMethodsService } from "@/_services";
import { paymentService } from "@/_services";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/fr";
import "dayjs/locale/ar";
import { I18nN, useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { currencyConfig } from "@/_helpers";

dayjs.extend(relativeTime);

const modalWidth = ref(720);
const scrollValue = ref(1500);
const popupWidth = ref(720);
const CollapsStatus = ref(false);
const customPagination = ref("10");
const { locale } = useI18n();
const router = useRouter();
const navigatePage = (link: string) => {
  router.push(link);
};

const searchInput = ref<HTMLInputElement | null>(null);

let PersistSearch = "";
const loading = ref(false);
const isDisabled = ref(false);
const { t } = useI18n();
const columns = [
  {
    title: t("Pos.PaymentMethod"),
    key: "payment_method",
    dataIndex: "payment_method",
    customFilterDropdown: true,
  },

  {
    title: t("Pos.PaidAmount"),
    key: "amount_paid",
    dataIndex: "amount_paid",
  },
  {
    title: t("Pos.paymentStatus"),
    key: "paidStatus",
    dataIndex: "paidStatus",
  },

  {
    title: t("Pos.CreatedAt"),
    key: "created_at",
    dataIndex: "created_at",
  },

  {
    title: t("Pos.Actions"),
    key: "actions",
    dataIndex: "actions",
  },
];
const lang = localStorage.getItem("Language") || "en";
const formatSaleDate = (dateString: string) => {
  return dayjs(dateString).locale(lang).fromNow();
  // return dayjs(dateString).locale(lang).fromNow();
};

//form
const formRef = ref();
const visible = ref<boolean>(false);

const reactiveData = reactive({
  data: [] as any[],
  action: "",
  totalPages: 0,
  searchText: "",
  searchedColumn: "",
  selectedType: "",
  totalPagesSearch: 0,
  beginSearch: false,
  fileChanged: false,
});

const reactiveData2 = reactive({
  data: [] as any[],
  action: "",
});

const form = reactive({
  id: "",
  name: "",
  description: "",
  address: "",
  phone: "",
  email: "",
  username: "",
  password: "",
  payment_method_id: "",
  config: "",
  status: "",
  accountNumber: "",
  active: "",
  isDisabled: false,
  currency_code: "",
  currency: "",
  amount_paid: "",
  payment_method: "",
  paidStatus: "",
  notes: "",
  firstname: "",
  lastname: "",
  discount: 0,
  discount_type: "",
  paid: "",
  customLastPage: 0,
});

const resetForm = () => {
  form.id = "";
  form.name = "";
  form.description = "";
  form.address = "";
  form.phone = "";
  form.email = "";
  form.username = "";
  form.payment_method_id = "";
  form.status = "";
  form.password = "";
  form.active = "";
  (form.paidStatus = ""), (form.isDisabled = false);
};

const perPage = ref<number>(10);
const currentPage = ref<number>(1);
const rules: Record<string, Rule[]> = {
  name: [{ required: true, message: "Please enter a name" }],
  contact_info: [{ required: true, message: "Please enter a contact info" }],
};

const value = ref<string>("1");

const formatDate = (dateString: any) => {
  return dayjs(dateString);
};

const textDirection = computed(() => {
  return locale.value === "ar" ? "rtl" : "ltr";
});

const formatDisplayDate = (dateString: string) => {
  return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
};

const formatName = (dateString: any) => {
  if (dateString && dateString.method) {
    if (dateString.method.name) {
      return dateString.method.name;
    }
  }

  return "";
};

const formatcurrency_code = (dateString: any) => {
  if (dateString && dateString.currency) {
    if (dateString.currency.code) {
      return dateString.currency.code;
    }
  }
  return "";
};

const addCodeToAmount = (dateString: any) => {
  if (dateString && dateString.amount_paid) {
    const currenciesString = localStorage.getItem("currencies");
    if (currenciesString === null) {
      return;
    }
    const currencies = JSON.parse(currenciesString);
    const currencyCode = currencies.code;
    const decimalPrecision = 3;

    console.log("currencyCode", currencyCode);
    console.log("dateString", dateString.amount_paid);
    console.log("decimalPrecision", decimalPrecision);

    return currencyConfig.formatAmount(
      dateString.amount_paid,
      currencyCode,
      decimalPrecision
    );
  }
};

const selectedType = (value: any) => {
  console.log("selected radio option ", value.target.value);
  reactiveData.selectedType = value.target.value;
};

const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
  customPagination.value = "10";
  currentPage.value = 1;
  confirm();
  reactiveData.searchText = selectedKeys[0];
  console.log("selected type is ", reactiveData.selectedType);
  reactiveData.searchedColumn = dataIndex;
  PersistSearch = reactiveData.selectedType;
  filter(PersistSearch);
};

const filter = (value: string) => {
  currentPage.value = 1;
  paymentService
    .filterWithPagination(
      value,
      currentPage.value.toString(),
      customPagination.value
    )
    .then((res) => {
      reactiveData.data = res.data;
      reactiveData.totalPagesSearch = res.meta.total;
      form.customLastPage = res.meta.last_page * 10;
      reactiveData.beginSearch = true;
    })
    .catch((error) => {
      console.log("error fetching", error);
    });
};

const handlePageChange = (newPage: any) => {
  currentPage.value = newPage;
  loading.value = true;

  //console.log('per page', perPage.value);
  //console.log('page', currentPage.value)
  if (reactiveData.selectedType == "") {
    paymentService
      .filterWithPaginationWithoutMethod(
        currentPage.value.toString(),
        customPagination.value
      )
      .then((response) => {
        //console.log('data', response.data)
        reactiveData.data = response.data;
        form.customLastPage = response.meta.last_page * 10;
      })
      .catch((error) => {
        //console.log("error api : get products", error);
      })
      .finally(() => {
        loading.value = false;
      });
  } else {
    paymentService
      .filterWithPagination(
        reactiveData.selectedType.toLocaleLowerCase(),
        currentPage.value.toString(),
        customPagination.value
      )
      .then((response) => {
        //console.log('data', response.data)
        reactiveData.data = response.data;
        form.customLastPage = response.meta.last_page * 10;
      })
      .catch((error) => {
        //console.log("error api : get products", error);
      })
      .finally(() => {
        loading.value = false;
      });
  }
};

const handleReset = (clearFilter: (arg: any) => void) => {
  currentPage.value = 1;
  clearFilter({ confirm: true });
  reactiveData.searchText = "";
  value.value ="";
  reactiveData.selectedType = "";
  customPagination.value = "10";
  getPaymentMethods();
};
const formatpayment_method = (dateString: any) => {
  if (dateString !== null) {
    if (dateString.payment_method.method.name) {
      return dateString.payment_method.method.name;
    }
  }
  return "";
};

const FormatEmptyNote = (dateString: any) => {
  if (dateString && dateString.notes) {
    return dateString.notes;
  } else {
    return "---";
  }
};

const formatID = (dateString: any) => {
  if (dateString && dateString.id) {
    if (dateString.id) {
      return "#" + " " + dateString.id;
    }
  }
  return "";
};

const formatpaidStatus = (dateString: any) => {
  if (dateString && dateString.sale) {
    if (dateString.sale.status) {
      return dateString.sale.status;
    }
  }
  return "";
};

const open = ref<boolean>(false);

const showAddDrawer = (state: boolean) => {
  updateModalWidth();
  open.value = state;
  reactiveData.action = "add";
  isDisabled.value = false;
  resetForm();
};

const afterOpenChange = (bool: boolean) => {
  //console.log('open', bool);
};

const showDrawer = (record: any) => {
  updateModalWidth();
  open.value = true;
  getPaymentMethodById(record.id);
  reactiveData.action = "update";
  //console.log(record.id)
  form.active = record.payment_method_id;
  isDisabled.value = true;
};

const openAdd = ref<boolean>(false);

const afterOpenAddChange = (bool: boolean) => {
  //console.log('open', bool);
};

const showSuccessNotification = (message: string) => {
  notification.success({
    message: message,
    duration: 3,
    top: "80px",
    style: {
      backgroundColor: "#bfffca8a",
      color: "white",
    },
  });
};

/*   const getStore = () => {
            storeService.getAll()
                .then((res) => {
                    reactiveData.data = res.data;
                })
                .catch((error: any) => {
                    console.error('Error fetching Stores:', error);
                });
        }*/

const getPaymentMethods = () => {
  loading.value = true;
  paymentService
    .getAll(currentPage.value, perPage.value)
    .then((res) => {
      reactiveData.data = res.data;
      reactiveData.totalPages = res.meta.total;
      form.customLastPage = res.meta.last_page * 10;
      //console.log(res.data)
    })
    .catch((error) => {
      //console.log("error api : get products", error);
    })
    .finally(() => {
      loading.value = false;
    });
};

const getPaymentNames = () => {
  paymentMethodsService
    .getNames()
    .then((res) => {
      reactiveData2.data = res.data;
      // console.log(res.data)
    })
    .catch((error: any) => {
      //console.error('Error fetching Stores:', error);
    });
};

const deletePaymentMethod = (id: string) => {
  paymentService
    .delete(id)
    .then((res) => {
      reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
      showSuccessNotification("Payment method deleted successfully");
    })
    .catch((err) => {
      //console.log(err)
    })
    .finally(() => {
      // console.log('finish')
    });
};

const getPaymentMethodById = (id: string) => {
  paymentService
    .show(id)
    .then((res) => {
      // form.user_name = res.data.user.name;
      //form.sale_name = res.data.sale.name;
      //form.currency = res.data.currency.name;
      //form.payment_method = res.data.payment.name;

      //form.em   ail = res.data.email;

      form.amount_paid = res.data.sale.total + " " + res.data.currency.code;
      form.paid = res.data.sale.status;
      form.notes = res.data.notes;
      form.currency = res.data.currency.name;
      form.discount = res.data.sale.discount_value;
      form.discount_type = res.data.sale.discount_type;
      form.payment_method = res.data.payment_method.name;
    })
    .catch((error) => {
      //console.error('Error fetching categories:', error);
    });
};

const choosePerPage = () => {
  currentPage.value = 1;
  console.log(customPagination.value);
  // currentPage.value = newPage;
  loading.value = true;
  //console.log('per page', perPage.value);
  //console.log('page', currentPage.value)
  paymentService
    .filterWithPagination(
      reactiveData.selectedType,
      currentPage.value.toString(),
      customPagination.value
    )
    .then((response) => {
      //console.log('data', response.data)
      reactiveData.data = response.data;
      form.customLastPage = response.meta.last_page * 10;
      console.log("lastPage", form.customLastPage);
    })
    .catch((error) => {
      //console.log("error api : get products", error);
    })
    .finally(() => {
      loading.value = false;
    });
};
const addStore = () => {
  formRef.value.validate().then(() => {
    let payload = {
      name: form.name,
      description: form.description,
      address: form.address,
      phone: form.phone,
      email: form.email,
      username: form.username,
      password: form.password,
    };
    storeService
      .create(payload)
      .then((res) => {
        reactiveData.data.push(res.store);
      })
      .catch((err) => {
        //console.log(err)
      })
      .finally(() => {
        open.value = false;
      });
  });
};

const closePopup = () => {
  open.value = false;
};

const addPaymentMethod = () => {
  formRef.value.validate().then(() => {
    let payload = {
      payment_method_id: form.active,
      status: form.status,
      config: "null",
    };
    paymentMethodsService
      .create(payload)
      .then((res) => {
        reactiveData.data.push(res.data);
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        open.value = false;
        location.reload(); // Refresh the page
      });
  });
};

const updatePaymentMethod = (id: any) => {
  let payload = {
    payment_method_id: 3,
    config: {},
    status: "active",
  };
  paymentMethodsService
    .update(id, payload)
    .then((res) => {
      reactiveData.data = reactiveData.data.map((el) =>
        el.id !== id ? el : res.data
      );
    })
    .catch((err) => {
      //console.log(err)
    })
    .finally(() => {
      // resetAdd();
      open.value = false;
      location.reload();
    });
};

const deleteStore = (id: string) => {
  storeService
    .delete(id)
    .then((res) => {
      reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
      showSuccessNotification("Store deleted successfully");
    })
    .catch((err) => {
      //console.log(err)
    })
    .finally(() => {
      //console.log('finish')
    });
};

const showDeletePopup = (record: any) => {
  Swal.fire({
    title: "Delete Payment",
    text: "Are you sure you want to delte this payment",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#fc8019",
    confirmButtonText: "Delete",
    cancelButtonColor: "#d33",
    customClass: {
      popup: "swal2-popup",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      deletePaymentMethod(record.id);
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      // console.log("cancel delete ");
    }
  });
};

onMounted(() => {
  updateSiderWidth;
  window.addEventListener("resize", updateSiderWidth);
  //getStore();
  getPaymentMethods();
  getPaymentNames();
  searchInput.value = document.querySelector(
    ".ant-table-filter-dropdown input"
  );
});

const updateModalWidth = () => {
  modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
};

const updateSiderWidth = () => {
  popupWidth.value = window.innerWidth >= 800 ? 729 : 250;
  CollapsStatus.value = window.innerWidth >= 1200 ? false : true;
  scrollValue.value = window.innerWidth >= 1200 ? 500 : 100;
};

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateSiderWidth);
});

// return {
//   modalWidth,
//   updateModalWidth,
//   scrollValue,
//   updateSiderWidth,
//   PersistSearch,
//   textDirection,
//   locale,
//   value,
//   rules,
//   handleReset,
//   handleSearch,
//   loading,
//   navigatePage,
//   formatID,
//   perPage,
//   currentPage,
//   handlePageChange,
//   addCodeToAmount,
//   t,
//   columns,
//   formRef,
//   form,
//   formatDisplayDate,
//   formatName,
//   reactiveData,
//   reactiveData2,
//   showAddDrawer,
//   showDrawer,
//   open,
//   formatDate,
//   addStore,
//   showDeletePopup,
//   visible,
//   addPaymentMethod,
//   updatePaymentMethod,
//   formatcurrency_code,
//   isDisabled,
//   closePopup,
//   formatpayment_method,
//   formatpaidStatus,
//   afterOpenChange,
//   selectedType,
//   choosePerPage,
//   customPagination,
//   FormatEmptyNote,
//   popupWidth,
// };
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}

.radio-item {
  margin-bottom: 10px;
}
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 8px;
}

.test-btn {
  color: #bfffca8a;
}
.input-form {
  color: black;
  background-color: white;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

/* loader container */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb-icon {
  cursor: pointer;
}
.is-rtl {
  direction: rtl;
}

.drawer-header-ar {
  padding-right: 2%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.drawer-header-all {
  display: flex;
  justify-content: start;
  align-items: center;
}

.select-input {
  width: 8%;
}
.paginationArrows {
  direction: ltr;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
  }
}
</style>