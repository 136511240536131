<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>
  <div>
    <a-breadcrumb
      style="margin: 16px 0"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Home") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item> {{ t("Pos.Cart") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div class="btn-container"></div>
  <div>
    <a-table
      :columns="columns"
      :data-source="reactiveData.data"
      :pagination="false"
      class="table-container"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'total'">
          {{ addCodeToAmount(record) }}
        </template>

        <template v-if="column.key == 'DeviceName'">
          {{ ConcatDeviceName(record) }}
        </template>

        <template v-if="column.key == 'TableName'">
          {{ ConcatTableName(record) }}
        </template>

        <template v-if="column.key == 'TableCapacity'">
          {{ ConcatTableCapacity(record) }}
        </template>

        <template v-if="column.key == 'instantStatus'">

          <a-tag
            :bordered="false"
            color="processing"
            v-if="ConcatInstantStatus(record) == 'reserved'"
          >
            <template #icon>
              <check-circle-outlined />
            </template>
            {{ t("Pos.Reserved") }}
          </a-tag>

          <a-tag
            :bordered="false"
            color="success"
            v-if="ConcatInstantStatus(record) == 'available'"
          >
            <template #icon>
              <check-circle-outlined />
            </template>
            {{ t("Pos.Available") }}
          </a-tag>

          <a-tag
            :bordered="false"
            color="error"
            v-if="ConcatInstantStatus(record) == 'occupied'"
          >
            <template #icon>
              <close-circle-outlined />
            </template>
            {{ t("Pos.Occupied") }}
          </a-tag>
        </template>

        <template v-if="column.key == 'status'">
          <a-tag
            :bordered="false"
            color="warning"
            v-if="record.status === 'pending'"
          >
            <template #icon>
              <ClockCircleOutlined />
            </template>
            {{ t("Pos.Pending") }}
          </a-tag>
          <a-tag
            :bordered="false"
            color="error"
            v-if="record.status === 'rejected'"
          >
            <template #icon>
              <CloseCircleOutlined />
            </template>
            {{ t("Pos.Rejected") }}
          </a-tag>

          <a-tag
            :bordered="false"
            color="success"
            v-if="record.status === 'completed'"
          >
            <template #icon>
              <DownCircleOutlined />
            </template>
            {{ t("Pos.Completed") }}
          </a-tag>

          <a-tag
            :bordered="false"
            color="success"
            v-if="record.status === 'preparing'"
          >
            <template #icon>
              <ClockCircleOutlined />
            </template>
            {{ t("Pos.Preparing") }}
          </a-tag>
        </template>
      </template>

      <template
        #customFilterDropdown="{ selectedKeys, confirm, clearFilters, column }"
      >
        <div style="padding: 8px">
          <template v-if="column.key === 'payment_method'">
            <div class="search-btn-radio">
              <a-radio-group
                v-model:value="value"
                @change="selectedType"
                class="search-radio-grp"
              >
                <a-col>
                  <a-radio value="Cash" class="radio-item">Cash</a-radio>
                </a-col>
                <a-col>
                  <a-radio value="Credit Card	" class="radio-item"
                    >Credit Card</a-radio
                  >
                </a-col>
                <a-col>
                  <a-radio value="Ticket" class="radio-item">Ticket</a-radio>
                </a-col>
              </a-radio-group>
            </div>
          </template>
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            {{ t("Pos.Search") }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            {{ t("Pos.Reset") }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
    </a-table>

    <div class="pagination">
      <a-pagination
        class="paginationArrows"
        v-model:current="currentPage"
        :total="form.customLastPage"
        @change="handlePageChange"
        :default-page-size="perPage"
        :showSizeChanger="false"
      />
      <a-select
        class="select-input"
        v-model:value="customPagination"
        @change="choosePerPage"
        v-if="form.customLastPage > 10"
      >
        <a-select-option value="10">10 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="20">20 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="50">50 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="100">100 / {{ t("Pos.page") }}</a-select-option>
      </a-select>
    </div>
    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      placement="right"
      :width="720"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
    >
      <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
          <span>{{ t("Pos.PaymentDetails") }}</span>
        </div>
        <div class="drawer-header-all" v-if="locale !== 'ar'">
          <span>{{ t("Pos.PaymentDetails") }}</span>
        </div>
      </template>
      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <template #header>
          <div class="custom-header">{{ t("Pos.GeneralInformations") }}</div>
        </template>

        <a-row :gutter="16">
          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="amountPaid">
                {{ t("Pos.PaidAmount") }} :</span
              >{{ form.amount_paid }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="paymentMethod">
                {{ t("Pos.PaymentMethod") }} :</span
              >
              {{ form.payment_method }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="currency">
                {{ t("Pos.currency") }} :</span
              >
              {{ form.currency }}
            </p>
          </a-col>
          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="discountValue">
                {{ t("Pos.discount") }} :</span
              >
              {{ form.discount }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title">{{ t("Pos.discountType") }} : </span>
              <template v-if="form.discount_type === 'percentage'">
                {{ t("Pos.Percentage") }}
              </template>
              <template v-if="form.discount_type === 'cash'">
                {{ t("Pos.Cash") }}
              </template>
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="notes">
                {{ t("Pos.Notes") }} :</span
              >
              {{ form.notes }}
            </p>
          </a-col>
        </a-row>
      </a-form>

      <template #footer>
        <a-button type="primary" danger @click="closePopup">
          {{ t("Pos.Close") }}</a-button
        >
      </template>
    </a-drawer>
  </div>
</template>
  <script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRaw,
  computed,
} from "vue";
import type { Rule } from "ant-design-vue/es/form";
import Swal from "sweetalert2";
import {
  SearchOutlined,
  CloseCircleOutlined,
  DownCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import { notification } from "ant-design-vue";
import { storeService } from "@/_services";
import { paymentMethodsService } from "@/_services";
import { paymentService, cartService } from "@/_services";
import dayjs from "dayjs";
import { I18nN, useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { currencyConfig } from "@/_helpers";

export default defineComponent({
  name: "indexStore",
  components: {
    LbrxLoaderSpinner,
    SearchOutlined,
    CloseCircleOutlined,
    DownCircleOutlined,
    ClockCircleOutlined,
  },
  setup() {
    const customPagination = ref("10");
    const { locale } = useI18n();
    const router = useRouter();
    const navigatePage = (link: string) => {
      router.push(link);
    };

    const searchInput = ref<HTMLInputElement | null>(null);

    let PersistSearch = "";
    const loading = ref(false);
    const isDisabled = ref(false);
    const { t } = useI18n();
    const columns = [
      {
        title: t("Pos.Device"),
        key: "DeviceName",
        dataIndex: "DeviceName",
      },
      {
        title: t("Pos.Table"),
        key: "TableName",
        dataIndex: "TableName",
      },
      {
        title: t("Pos.TableCapacity"),
        key: "TableCapacity",
        dataIndex: "TableCapacity",
      },

      {
        title: t("Pos.InstantStatus"),
        key: "instantStatus",
        dataIndex: "instantStatus",
      },

      {
        title: t("Pos.Total"),
        key: "total",
        dataIndex: "total",
      },

      {
        title: t("Pos.Status"),
        key: "status",
        dataIndex: "status",
      },
    ];

    //form
    const formRef = ref();
    const visible = ref<boolean>(false);

    const reactiveData = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchText: "",
      searchedColumn: "",
      selectedType: "",
      totalPagesSearch: 0,
      beginSearch: false,
      fileChanged: false,
    });

    const reactiveData2 = reactive({
      data: [] as any[],
      action: "",
    });

    const form = reactive({
      id: "",
      name: "",
      description: "",
      address: "",
      phone: "",
      email: "",
      username: "",
      password: "",
      payment_method_id: "",
      config: "",
      status: "",
      accountNumber: "",
      active: "",
      isDisabled: false,
      currency_code: "",
      currency: "",
      amount_paid: "",
      payment_method: "",
      paidStatus: "",
      notes: "",
      firstname: "",
      lastname: "",
      discount: 0,
      discount_type: "",
      paid: "",
      customLastPage: 0,
    });

    const resetForm = () => {
      form.id = "";
      form.name = "";
      form.description = "";
      form.address = "";
      form.phone = "";
      form.email = "";
      form.username = "";
      form.payment_method_id = "";
      form.status = "";
      form.password = "";
      form.active = "";
      (form.paidStatus = ""), (form.isDisabled = false);
    };

    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);
    const rules: Record<string, Rule[]> = {
      name: [{ required: true, message: "Please enter a name" }],
      contact_info: [
        { required: true, message: "Please enter a contact info" },
      ],
    };

    const value = ref<string>("1");

    const formatDate = (dateString: any) => {
      return dayjs(dateString);
    };

    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });

    const formatDisplayDate = (dateString: string) => {
      return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
    };

    const formatName = (dateString: any) => {
      if (dateString && dateString.method) {
        if (dateString.method.name) {
          return dateString.method.name;
        }
      }

      return "";
    };

    const addCodeToAmount = (dateString: any) => {
      if (dateString && dateString.total) {
        const currenciesString = localStorage.getItem("currencies");
        if (currenciesString === null) {
          return;
        }
        const currencies = JSON.parse(currenciesString);
        const currencyCode = currencies.code;
        const decimalPrecision = 3;
        return currencyConfig.formatAmount(
          dateString.total,
          currencyCode,
          decimalPrecision
        );
      }
    };

    const ConcatDeviceName = (dateString: any) => {
      if (dateString && dateString.device) {
        return dateString.device.name;
      } else {
        return "---";
      }
    };

    const ConcatTableName = (dateString: any) => {
      if (dateString && dateString.table) {
        return dateString.table.number;
      } else {
        return "---";
      }
    };

    const ConcatTableCapacity = (dateString: any) => {
      if (dateString && dateString.table) {
        return dateString.table.seating_capacity;
      } else {
        return "---";
      }
    };

    const ConcatInstantStatus = (dateString: any) => {
      if (dateString && dateString.table) {
        return dateString.table.instantStatus;
      } else {
        return "---";
      }
    };

    const selectedType = (value: any) => {
      console.log("selected radio option ", value.target.value);
      reactiveData.selectedType = value.target.value;
    };

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
      customPagination.value = "10";
      currentPage.value = 1;
      confirm();
      reactiveData.searchText = selectedKeys[0];
      console.log("selected type is ", reactiveData.selectedType);
      reactiveData.searchedColumn = dataIndex;
      PersistSearch = reactiveData.selectedType;
      filter(PersistSearch);
    };

    const filter = (value: string) => {
      currentPage.value = 1;
      paymentService
        .filterWithPagination(
          value,
          currentPage.value.toString(),
          customPagination.value
        )
        .then((res) => {
          reactiveData.data = res.data;
          reactiveData.totalPagesSearch = res.meta.total;
          form.customLastPage = res.meta.last_page * 10;
          reactiveData.beginSearch = true;
        })
        .catch((error) => {
          console.log("error fetching", error);
        });
    };

    const handlePageChange = (newPage: any) => {
      currentPage.value = newPage;
      loading.value = true;

      //console.log('per page', perPage.value);
      //console.log('page', currentPage.value)

      cartService
        .getAll(currentPage.value.toString(), customPagination.value)
        .then((response) => {
          //console.log('data', response.data)
          reactiveData.data = response.data;
          form.customLastPage = response.meta.last_page * 10;
        })
        .catch((error) => {
          //console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      currentPage.value = 1;
      clearFilter({ confirm: true });
      reactiveData.searchText = "";
      reactiveData.selectedType = "";
      customPagination.value = "10";
    };

    const formatpaidStatus = (dateString: any) => {
      if (dateString && dateString.sale) {
        if (dateString.sale.status) {
          return dateString.sale.status;
        }
      }
      return "";
    };

    const open = ref<boolean>(false);

    const showAddDrawer = (state: boolean) => {
      open.value = state;
      reactiveData.action = "add";
      isDisabled.value = false;
      resetForm();
    };

    const afterOpenChange = (bool: boolean) => {
      //console.log('open', bool);
    };

    const showDrawer = (record: any) => {
      open.value = true;
      reactiveData.action = "update";
      //console.log(record.id)
      form.active = record.payment_method_id;
      isDisabled.value = true;
    };

    const openAdd = ref<boolean>(false);

    const afterOpenAddChange = (bool: boolean) => {
      //console.log('open', bool);
    };

    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#bfffca8a",
          color: "white",
        },
      });
    };

    /*   const getStore = () => {
              storeService.getAll()
                  .then((res) => {
                      reactiveData.data = res.data;
                  })
                  .catch((error: any) => {
                      console.error('Error fetching Stores:', error);
                  });
          }*/

    const getAllCarts = () => {
      loading.value = true;
      cartService
        .getAll(currentPage.value, perPage.value)
        .then((res) => {
          reactiveData.data = res.data;
          reactiveData.totalPages = res.meta.total;
          form.customLastPage = res.meta.last_page * 10;
          //console.log(res.data)
        })
        .catch((error) => {
          //console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getPaymentNames = () => {
      paymentMethodsService
        .getNames()
        .then((res) => {
          reactiveData2.data = res.data;
          // console.log(res.data)
        })
        .catch((error: any) => {
          //console.error('Error fetching Stores:', error);
        });
    };

    const deletePaymentMethod = (id: string) => {
      paymentService
        .delete(id)
        .then((res) => {
          reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
          showSuccessNotification("Payment method deleted successfully");
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => {
          // console.log('finish')
        });
    };

    const choosePerPage = () => {
      currentPage.value = 1;
      // currentPage.value = newPage;
      loading.value = true;
      //console.log('per page', perPage.value);
      //console.log('page', currentPage.value)
      cartService
        .getAll(currentPage.value.toString(), customPagination.value)
        .then((response) => {
          //console.log('data', response.data)
          reactiveData.data = response.data;
          form.customLastPage = response.meta.last_page * 10;
          console.log("lastPage", form.customLastPage);
        })
        .catch((error) => {
          //console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const addStore = () => {
      formRef.value.validate().then(() => {
        let payload = {
          name: form.name,
          description: form.description,
          address: form.address,
          phone: form.phone,
          email: form.email,
          username: form.username,
          password: form.password,
        };
        storeService
          .create(payload)
          .then((res) => {
            reactiveData.data.push(res.store);
          })
          .catch((err) => {
            //console.log(err)
          })
          .finally(() => {
            open.value = false;
          });
      });
    };

    const closePopup = () => {
      open.value = false;
    };

    const addPaymentMethod = () => {
      formRef.value.validate().then(() => {
        let payload = {
          payment_method_id: form.active,
          status: form.status,
          config: "null",
        };
        paymentMethodsService
          .create(payload)
          .then((res) => {
            reactiveData.data.push(res.data);
          })
          .catch((err) => {
            //console.log(err);
          })
          .finally(() => {
            open.value = false;
            location.reload(); // Refresh the page
          });
      });
    };

    const updatePaymentMethod = (id: any) => {
      let payload = {
        payment_method_id: 3,
        config: {},
        status: "active",
      };
      paymentMethodsService
        .update(id, payload)
        .then((res) => {
          reactiveData.data = reactiveData.data.map((el) =>
            el.id !== id ? el : res.data
          );
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => {
          // resetAdd();
          open.value = false;
          location.reload();
        });
    };

    const deleteStore = (id: string) => {
      storeService
        .delete(id)
        .then((res) => {
          reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
          showSuccessNotification("Store deleted successfully");
        })
        .catch((err) => {
          //console.log(err)
        })
        .finally(() => {
          //console.log('finish')
        });
    };

    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: "Delete Payment",
        text: "Are you sure you want to delte this payment",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: "Delete",
        cancelButtonColor: "#d33",
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deletePaymentMethod(record.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // console.log("cancel delete ");
        }
      });
    };

    onMounted(() => {
      getAllCarts();
      //getStore();
      getPaymentNames();
      searchInput.value = document.querySelector(
        ".ant-table-filter-dropdown input"
      );
    });

    return {
      PersistSearch,
      textDirection,
      locale,
      value,
      rules,
      handleReset,
      handleSearch,
      loading,
      navigatePage,
      perPage,
      currentPage,
      handlePageChange,
      addCodeToAmount,
      t,
      columns,
      formRef,
      form,
      formatDisplayDate,
      formatName,
      reactiveData,
      reactiveData2,
      showAddDrawer,
      showDrawer,
      open,
      formatDate,
      addStore,
      showDeletePopup,
      visible,
      addPaymentMethod,
      updatePaymentMethod,
      isDisabled,
      closePopup,
      formatpaidStatus,
      afterOpenChange,
      selectedType,
      choosePerPage,
      ConcatDeviceName,
      ConcatTableName,
      ConcatTableCapacity,
      ConcatInstantStatus,
      customPagination,
    };
  },
});
</script>
  
  <style scoped>
.radio-item {
  margin-bottom: 10px;
}
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 8px;
}

.test-btn {
  color: #bfffca8a;
}
.input-form {
  color: black;
  background-color: white;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

/* loader container */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb-icon {
  cursor: pointer;
}
.is-rtl {
  direction: rtl;
}

.drawer-header-ar {
  padding-right: 2%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.drawer-header-all {
  display: flex;
  justify-content: start;
  align-items: center;
}

.select-input {
  width: 8%;
}

.paginationArrows {
  direction: ltr;
}

.table-container {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
    margin-top: 10px;
  }
}
</style>