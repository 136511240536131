<template>

 <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{t('Pos.Dashboard')}}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{t('Pos.Tables')}}</a-breadcrumb-item>
      <a-breadcrumb-item>{{t('Pos.Tables')}}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  
  <div class="btn-container">
    <a-button class="add-btn" @click="showAddDrawer" >
      {{ t('Pos.Add') }}
    </a-button>
  </div>

  <div>
    <a-table
      :columns="columns"
      :data-source="state.data"
      :pagination="false"
      class="table-container"
      >

      <template #emptyText>
        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.key=='actions'">
            <a-space>

              <a-tooltip>
                <template #title>{{ t('Pos.View') }}</template>
                <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                <template #icon>
                    <EyeOutlined/>
                </template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>{{ t('Pos.Edit') }}</template>
                <a-button type="primary"  @click="() => showDrawer(record)">
                <template #icon>
                    <EditOutlined/>
                </template>
                </a-button>
              </a-tooltip>
                

              <a-tooltip>
                <template #title>{{ t('Pos.Delete') }}</template>
                <a-button type="primary" danger @click="deleteTable(record)">
                <template #icon>
                  <DeleteOutlined />
                </template>
                </a-button>
              </a-tooltip>


            </a-space>
        </template>


        <template v-if="column.key==='instantStatus'">
          <a-tag :bordered="false" color="processing" v-if="record.instantStatus==='reserved'">
              <template #icon>
                <check-circle-outlined />
              </template>
             {{ t('Pos.Reserved') }}
            </a-tag>

            <a-tag :bordered="false" color="success" v-if="record.instantStatus==='available'">
              <template #icon>
                <check-circle-outlined />
              </template>
              {{ t('Pos.Available') }}
            </a-tag>

            <a-tag :bordered="false" color="error" v-if="record.instantStatus==='occupied'">
              <template #icon>
                <close-circle-outlined />
              </template>
              {{ t('Pos.Occupied') }}
            </a-tag>
        </template>
      </template>

      

      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
          <template  v-if="column.key === 'stockable'">
            <a-input
              ref="searchInput"
              :placeholder="`Search`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />  
          </template>

          <template  v-if="column.key === 'instantStatus'">
              <div class="search-btn-radio">
                <a-radio-group  v-model:value="value" @change="selectedType" class="search-radio-grp">
                  <a-radio value="available" class="radio-item"> {{ t('Pos.Available') }}</a-radio>
                  <a-radio value="reserved" class="radio-item"> {{ t('Pos.Reserved') }}</a-radio>
                  <a-radio value="occupied" class="radio-item">{{ t('Pos.Occupied') }}</a-radio>
                </a-radio-group>  
              </div>   
          </template>

          <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
          
            {{ t('Pos.Search') }}
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            {{ t('Pos.Reset') }}
          </a-button>
            
        </div>
      </template>
        
      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>


          
    </a-table>

    <div class="pagination">
      <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange"/>

      <a-select
        class="select-input"
        v-model:value="selectPagination"
        @change="handleSelectPaginationChange"
        v-if="state.totalPages>10"
      >
        <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
        <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
        <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
        <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
      </a-select>
      </div>



    <!-- <div class="pagination" v-if="state.totalPagesSearch ===0  && !state.beginSearch ">
      <a-pagination v-model:current="currentPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger=false />
    </div>

    <div class="pagination" v-if="state.totalPagesSearch !==0 && state.beginSearch===true ">
      <a-pagination v-model:current="currentPageSearch" :total="state.totalPagesSearch" @change="handlePageChangeSearch"  :default-page-size="perPageSearch" :showSizeChanger=false />
    </div> -->

    <!-- update drawer -->
    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width=modalWidth
      @after-open-change="afterOpenChange"
    >
      <template #title>
        <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateTable')}}</p>
      </template>
      <a-form  ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
        <a-row :gutter="16">   
            <a-col :span="12">
              <a-form-item  name="number">
              <template #label>
                <span class="label-form">{{ t('Pos.Number') }}</span>
              </template>
              <a-input  v-model:value="form.number" :placeholder="t('Pos.Number')" class="input-form"/>
              </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item  name="seating_capacity">
                <template #label>
                    <span class="label-form">{{ t('Pos.SeatingCapacity') }}</span>
                </template>
                <a-input  v-model:value="form.seating_capacity" :placeholder="t('Pos.SeatingCapacity')" class="input-form"/>
                </a-form-item>
            </a-col>
        </a-row>

        <a-row :gutter="16">   
            <a-col :span="12">
                <a-form-item  name="status">
                <template #label>
                    <span class="label-form">{{ t('Pos.Status') }}</span>
                </template>
                <a-select v-model:value="form.status" show-search disabled>
                  <a-select-option value="available">{{ t('Pos.Available') }}</a-select-option>
                  <a-select-option value="reserved">{{ t('Pos.Reserved') }}</a-select-option>
                  <a-select-option value="occupied">{{ t('Pos.Occupied') }}</a-select-option>
                </a-select>
                </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item  name="shape">
                <template #label>
                  <span class="label-form">{{ t('Pos.Shape') }}</span>
                </template>
                <a-select v-model:value="form.shape" show-search :option-filter-prop="'label'">
                  <template #notFoundContent>
                    <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                  </template>
                  <a-select-option value="round" :label="t('Pos.Round') ">{{ t('Pos.Round') }}</a-select-option>
                  <a-select-option value="suqare" :label="t('Pos.Square') "> {{ t('Pos.Square') }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item  name="location_id">
              <template #label>
                <span class="label-form">{{ t('Pos.Location') }}</span>
              </template>

              <a-select v-model:value="form.locationName" @change="handleLocationChange">
                <a-select-option v-for="location in state.locations" :key="location.id" :value="location.id" :selected="location.id === form.location_id " >{{ location.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
              <a-form-item  name="uuid">
              <template #label>
                  <span class="label-form">{{ t('Pos.uuid') }}</span>
              </template>
              <a-input  v-model:value="form.uuid" :placeholder="t('Pos.uuid')" class="input-form"/>
              </a-form-item>
          </a-col>
        </a-row>

      </a-form>
      <template #footer>
        <a-space :class="{ 'is-rtl': textDirection==='rtl'}">
          <a-button  type="primary" @click="updateTable(form.id)">{{ t('Pos.Update') }}</a-button>
          <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
        </a-space>
      </template>

    </a-drawer>

    <!-- add drawer -->
    <a-drawer
      v-model:open="openAdd"
      class="custom-class"
      root-class-name="root-class-name"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width=modalWidth
      @close="resetFormAdd"
    >

      <template #title>
        <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddTable')}}</p>
      </template>

      <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
        <a-row :gutter="16">   
            <a-col :span="12">
                <a-form-item  name="number">
                <template #label>
                    <span class="label-form">{{ t('Pos.Number') }}</span>
                </template>
                <a-input  v-model:value="addForm.number" :placeholder="t('Pos.Number')" class="input-form"/>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item  name="seating_capacity">
                <template #label>
                    <span class="label-form">{{ t('Pos.SeatingCapacity') }}</span>
                </template>
                <a-input  v-model:value="addForm.seating_capacity" :placeholder="t('Pos.SeatingCapacity')" class="input-form"/>
                </a-form-item>
            </a-col>
        </a-row>

        <a-row :gutter="16">   
          <a-col :span="12">
            <a-form-item  name="status">
            <template #label>
              <span class="label-form">{{ t('Pos.Status') }}</span>
            </template>
            <a-select v-model:value="addForm.status" show-search :option-filter-prop="'label'" >

              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>

              <a-select-option value="available" :label="t('Pos.Available') " >{{ t('Pos.Available') }}</a-select-option>
              <a-select-option value="reserved" :label="t('Pos.Reserved')" >{{ t('Pos.Reserved') }}</a-select-option>
              <a-select-option value="occupied" :label="t('Pos.Occupied')">{{ t('Pos.Occupied') }}</a-select-option>
            </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item  name="shape">
            <template #label>
              <span class="label-form">{{ t('Pos.Shape') }}</span>
            </template>
            <a-select v-model:value="addForm.shape" show-search :option-filter-prop="'label'">

              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>

              <a-select-option value="round" :label="t('Pos.Round') ">{{ t('Pos.Round') }}</a-select-option>
              <a-select-option value="suqare" :label="t('Pos.Square') ">{{ t('Pos.Square') }}</a-select-option>
            </a-select>
            </a-form-item>
          </a-col>

        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item  name="location">
            <template #label>
              <span class="label-form">{{ t('Pos.Location') }}</span>
            </template>
            <a-select v-model:value="addForm.location" show-search :option-filter-prop="'label'">

              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>

              <a-select-option v-for="item in state.locations" :key="item.id" :value="item.id" :label="item.name" >{{ item.name }}</a-select-option>-->
            </a-select>
            </a-form-item>
          </a-col>
        </a-row>
       
            
            
      </a-form>
      <template #footer>
        <a-space :class="{ 'is-rtl': textDirection==='rtl'}">
          <a-button  type="primary" @click="addTable">{{ t('Pos.Add') }}</a-button>
          <a-button type="primary" danger  @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
        </a-space>
      </template>
    </a-drawer>

    <!-- display drawer -->
    <a-drawer
      v-model:open="openDisplay"
      class="custom-class"
      root-class-name="root-class-name"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width=modalWidth
    >

      <template #title>
        <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.TableInfo')}}</p>
      </template>
      <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">   
        <a-col :span="12">
          <p><span class="info-title">{{ t('Pos.Number') }} :</span>{{form.number}}</p>
        </a-col>
        <a-col :span="12">
          <p><span class="info-title">{{ t('Pos.SeatingCapacity') }} :</span>{{form.seating_capacity}}</p>
        </a-col>
      </a-row>

      <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">   
        <a-col :span="12">
          <p>
            <span class="info-title">{{ t('Pos.Status') }} :</span>
            <a-tag :bordered="false" color="processing" v-if="form.status==='reserved'">
              <template #icon>
                <check-circle-outlined />
              </template>
              {{ t('Pos.Reserved') }}
            </a-tag>

            <a-tag :bordered="false" color="success" v-if="form.status==='available'">
              <template #icon>
                <check-circle-outlined />
              </template>
              {{ t('Pos.Available') }}
            </a-tag>

            <a-tag :bordered="false" color="error" v-if="form.status==='occupied'">
              <template #icon>
                <close-circle-outlined />
              </template>
              {{ t('Pos.Occupied') }}
            </a-tag>
          </p>
        </a-col>

        <a-col :span="12">
          <p><span class="info-title">{{ t('Pos.Shape') }} :</span>
          <a-tag v-if="form.shape =='round'" >{{t("Pos.round")}}</a-tag>
        <a-tag v-if="form.shape =='square'" >{{t("Pos.square")}}</a-tag>
          </p>
        </a-col>   
      </a-row>    

      <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
        <a-col :span="12">
          <p><span class="info-title">{{ t('Pos.Location') }} :</span>{{form.locationName}}</p>
        </a-col> 
        <a-col :span="12">
          <p><span class="info-title">{{ t('Pos.uuid') }} :</span>{{form.uuid}}</p>
        </a-col> 
      </a-row>

    </a-drawer>



   
  </div>

</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  LoadingOutlined,
  CloseCircleOutlined
} from '@ant-design/icons-vue';
import dayjs from 'dayjs'; 
import { notification } from 'ant-design-vue';
import {tablesService, tableLocationService} from '@/_services';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { Empty } from 'ant-design-vue';
export default defineComponent({
  name: 'indexTables',
  components: {
    EyeOutlined,
    DeleteOutlined,
    LbrxLoaderSpinner,
    EditOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SearchOutlined,
      
   
  },
  setup() {
    const modalWidth = ref(720);
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    const { t } = useI18n();
    const router = useRouter();

    const navigatePage = (link: string) => {
      router.push(link); 
    };
    const loading = ref(false);

   
    const state = reactive({
      searchText: '',
      searchedColumn: '',
      data: [] as any[],
      locations:[] as any[],
      totalPages:0,
      totalPagesSearch:0,

      selectedType:'',
      beginSearch:false,

    });

    //search
    const searchInput = ref<HTMLInputElement | null>(null);
    const value = ref<string>('1');
    const selectedType =(value:any)=>{
      console.log('selected radio option ', value.target.value);
      state.selectedType = value.target.value;
      
    }

    

    


    //pagination
    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);

    const selectPagination = ref('10');

    const perPageSearch = ref<number>(10);
    const currentPageSearch = ref<number>(1);

     const handleSelectPaginationChange=(selectedItem:any)=>{
        console.log('selected Per page:', selectedItem);
        perPage.value=Number.parseInt(selectedItem);
        currentPage.value=1;

        if(state.beginSearch===true){

          if(state.selectedType!==''){
            loading.value = true;
            tablesService.filterStatusWithPagination(state.selectedType,currentPage.value.toString(),perPage.value).then((res)=>{
              state.data=res.data;
              state.totalPages=res.meta.total;
            }).catch((err:any) => {
              console.log(err)
            }).finally(()=>{
              loading.value = false;
            });
          }

           

        }else{

          loading.value=true;
          tablesService.getAllTables(perPage.value, currentPage.value).then((res)=>{
            state.data=res.data;
            state.totalPages=res.meta.total;
          }).catch((err:any) => {
            console.log(err)
          }).finally(()=>{
            loading.value = false;
          });

        }

        
    }




    const handlePageChange = (newPage:any) => {
      currentPage.value = newPage;
      loading.value = true;

      console.log('per page', perPage.value);
      console.log('page', currentPage.value);

      if(state.beginSearch===true){
        state.data=[]; 
        if(state.selectedType!==''){
          loading.value = true;

          tablesService.filterStatusWithPagination(state.selectedType,currentPage.value.toString(),perPage.value).then((res)=>{
            state.data=res.data;
            state.totalPages=res.meta.total;
          }).catch((err:any) => {
            console.log(err)
          }).finally(()=>{
            loading.value = false;
          });


        }

        

      }else{

        tablesService.getAllTables(perPage.value, currentPage.value).then((response)=>{
          console.log('data tables', response.data)
          state.data=response.data;
          state.totalPages=response.meta.total;
        }).catch((error)=>{
          console.log("error api : get tables", error);
        })
        .finally(()=>{
          loading.value = false;
        });

      }





      
    }

    const handlePageChangeSearch = (newPage:any) => {
      currentPageSearch.value = newPage;
      loading.value = true;

      console.log('per page', perPageSearch.value);
      console.log('page', currentPageSearch.value);

      state.data=[];
      tablesService.filterWithPagination("status",state.selectedType,currentPageSearch.value.toString(), perPage.value).then((res)=>{
        console.log('res', res.data);
        state.data=res.data;
        state.totalPagesSearch = res.meta.total;
      }).catch((err:any) => {
        console.log(err)
      }).finally(()=>{
        loading.value = false;
      })


    }

    const columns = [
      {
        title:t('Pos.TableNumber'),
        key:'number',
        dataIndex: 'number',
      },
      {
        title:  t('Pos.SeatingCapacity'),
        key:'seating_capacity',
        dataIndex: 'seating_capacity',
      },
     
      {
        title: t('Pos.Status'),
        key:'instantStatus',
        dataIndex: 'instantStatus',
        customFilterDropdown: true,
      },
      {
        title:  t('Pos.Actions'),
        key:'actions',
        dataIndex: 'actions',
      }
    ];

   


    //form  
    const formRef = ref();
    const addFormRef = ref();

    const form = reactive({
      id:'',
      number: '',
      seating_capacity: '',
      location_id: '',
      status: '',
      shape:'',
      uuid:'',
      locationName:'',

    });

    const addForm = reactive({
      id:'',
      number: '',
      seating_capacity: '',
      location: '',
      status: '',
      shape:''
    });

    const rules: Record<string, Rule[]> = {
      number: [{ required: true, message:  t('Pos.RequiredField') }],
      seating_capacity: [
        { required: true, message:  t('Pos.RequiredField') },
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject(t('Pos.numericField'));
          }
        }

        ],
      location: [{ required: true, message:  t('Pos.RequiredField') }],
      status: [{ required: true, message:  t('Pos.RequiredField') }],
      shape:[{ required: true, message:  t('Pos.RequiredField') }],
    };

    //notification
    const showSuccessNotification  = (message:string)=>{
      notification.success({
        message: message,
        duration: 3,
        top: '80px',
        style:{
        backgroundColor: '#bfffca8a',
        color: 'white'
        }
      });
    };

    const open = ref<boolean>(false);

    const afterOpenChange = (bool: boolean) => {
      console.log('open', bool);
    };

    const showDrawer = (record:any) => {
      updateModalWidth();
      open.value = true;
      getTableById(record.id);
    };

     const showDisplayDrawer =(record:any)=>{
        updateModalWidth();
        openDisplay.value = true;
        getTableById(record.id);
      }

    const getTableById =(id:string)=>{
      tablesService.show(id).then((res)=>{
        form.id=res.data.id;
        form.number=res.data.number
        form.seating_capacity=res.data.seating_capacity;
        form.location_id=res.data.table_location_id;
        form.shape=res.data.shape;
        form.status=res.data.instantStatus;
        form.uuid=res.data.uuid;
        form.locationName=res.data.location.name;
      }).catch(error => {
        console.error('Error fetching table by id:', error);
      });
    }

    const openAdd = ref<boolean>(false);
    const openDisplay = ref<boolean>(false);


    const afterOpenAddChange = (bool: boolean) => {
      console.log('open', bool);
    };

    const showAddDrawer = () => {
      updateModalWidth();
      openAdd.value = true;
    };

    const resetFormAdd = () => {
      console.log("begin rest");
      addFormRef.value.resetFields();
    };

    const cancelUpdate=()=>{
      open.value=false;
    }

    const getTables=()=>{
      loading.value = true;
      tablesService.getAllTables(perPage.value, currentPage.value).then((response)=>{
        state.data=response.data;
        state.totalPages=response.meta.total;
      }).catch((error)=>{
        console.log("error get tables", error);
      }).finally(()=>{
        loading.value = false;
      });
    }

    const addTable =()=>{
      console.log("added values", toRaw(addForm));

      let payload={
        number:addForm.number,
        seating_capacity:addForm.seating_capacity,
        table_location_id:addForm.location.toString(),
        status:addForm.status,
        shape:addForm.shape,
        
      }
      console.log('payload', payload);
      
      addFormRef.value
      .validate()
      .then(()=>{
        loading.value=true;
        tablesService.addTable(payload)
        .then((response)=>{
          //state.data.push(response.data);
          showSuccessNotification(t('Pos.AddTableSuccess'));

          getTables();
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          loading.value=false;
          openAdd.value=false;
          resetFormAdd();
        }); 
      }).catch((err:any) => {
        console.log('error validation', err);
      });
      
    }

    const handleLocationChange=(value:any)=>{
      console.log('selected location', value);
      form.location_id=value;
    }

    const updateTable =(id:string)=>{
      console.log('id', id);
      console.log('location', form.location_id);
      let payload={
        number:form.number,
        seating_capacity:form.seating_capacity,
        table_location_id:form.location_id.toString(),
        status:form.status,
        shape:form.shape
        
      }
      console.log('payload', payload);
      loading.value=true;
      tablesService.updateTable(id,payload).then((response)=>{
        getTables();
        showSuccessNotification(t('Pos.UpdateTableSuccess'));
      }).catch((error) => {
        console.error('Error adding Worker:', error); 
      }).finally(()=>{
        loading.value=false;
        open.value=false;

      });
      
    }

    const deleteTable = (record: any) => {
      Swal.fire({
        title: t('Pos.TitleDeleteTable'),
        text: t('Pos.TextDeleteTable'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t('Pos.Delete'),
        cancelButtonText: t('Pos.Cancel'),
        cancelButtonColor: "#d33",
        customClass: {
        popup: 'swal2-popup'
        }

      }).then((result) => {
        if (result.isConfirmed) {

          _delete(record.id);
          
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      })
    };

    const _delete=(id:any)=>{

      tablesService.deleteTable(id).then((res)=>{
        showSuccessNotification(t('Pos.DeleteTableSuccess'));
        getTables();

      }).catch((error)=>{
        console.log('error delete table', error);
      })

    }

    const getLocations=()=>{
      tableLocationService.getAll().then((response)=>{
        state.locations=response.data;
        console.log('locations : ', state.locations);
      }).catch((error)=>{
        console.log('error get locations api', error);
      });
    }

    const filter=(key:string, value:string)=>{
      currentPage.value=1;
      loading.value=true;
      tablesService.filterWithPagination(key,value, currentPage.value.toString(), perPage.value).then((res)=>{
        state.data=res.data;
        state.totalPages=res.meta.total;
        state.beginSearch=true;
        loading.value=false;
      }).catch((error)=>{
        console.log('error fetching', error);
      });
    }

    const filterWithStatus=(status:string)=>{
      currentPage.value=1;
      loading.value=true;
      tablesService.filterStatusWithPagination(status, currentPage.value.toString(), perPage.value).then((res)=>{
        state.data=res.data;
        state.totalPages=res.meta.total;
        state.beginSearch=true;
        loading.value=false;
      }).catch((error)=>{
        console.log('error fetching', error);
      });
    }

    

    const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
      confirm();

      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;

      console.log('searched data', state.searchText);
      console.log('seareched column', state.searchedColumn);


      if (dataIndex === 'instantStatus' && state.selectedType!=='') {
        console.log('begin filter type', state.selectedType);
        
        filterWithStatus(state.selectedType);
        
      }
    };

    const handleReset =  (clearFilter: (arg: any) => void)=> {
      clearFilter({ confirm: true });
      state.searchText = '';
      state.selectedType='';
      state.totalPagesSearch=0;
      state.beginSearch=false;
      value.value='';

      currentPage.value=1;
      perPage.value=10;
      selectPagination.value='10';
      getTables();

      

    };

    //rtl
    const { locale } = useI18n();
    const textDirection = computed(() => {
      return locale.value === 'ar' ? 'rtl' :'ltr';}
    );


    const updateModalWidth = () => {
        modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };

    onMounted(() => {
      getTables(); 
      getLocations();  
      searchInput.value = document.querySelector('.ant-table-filter-dropdown input');

    });
    return {
      updateModalWidth,
      modalWidth,
      t,
      locale,
      textDirection,
      state,
      navigatePage,
      columns,
      formRef,
      addFormRef,
      form,
      addForm,
      rules,
      open,
      afterOpenChange,
      showDrawer,
      openAdd,
      afterOpenAddChange,
      showAddDrawer,
      resetFormAdd,
      addTable,
      updateTable,
      deleteTable,
      cancelUpdate,
      perPage,
      currentPage,
      loading,
      handlePageChange,
      openDisplay,
      showDisplayDrawer,
      searchInput,
      value,
      selectedType,
      handleSearch,
      handleReset,
      perPageSearch,
      currentPageSearch,
      handlePageChangeSearch,
      handleLocationChange,
      selectPagination,
      handleSelectPaginationChange,
      simpleImage
      





      
    






      



          
    };
  },
})
</script>

<style scoped>

  .breadcrumb-icon{
    cursor: pointer;
  }

.btn-container{
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.add-btn{
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}
.add-btn:hover{
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.label-form{
  font-family: "Exo 2", sans-serif; 
  font-size: 14px;
  font-weight: 600;
}

.date-picker{
  width:100%
}
.new-location{
  display: flex;
  align-items: center;
}
.location-btn{
  margin-top: 7.5px;
  width: 100%;
}

.pagination{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

  .view-btn{
    color: #fc8019;
    border-color: #fc8019;
    background: #fafafa;
  }

  .info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
  }

  .search-btn-radio{
    display: flex;
  }

  .search-radio-grp{
    display: flex;
    flex-direction: column !important;
  }

  .radio-item{
    margin-bottom: 10px;
  }

  /* CSS for RTL */
  .is-rtl {
    direction:rtl;
  }

  .paginationArrows{
  direction: ltr;
  margin-bottom: 16px;

  }

  .table-container {
    overflow-x: auto;
  }

  @media (max-width: 768px) {
    .pagination {
      flex-direction: column;
      align-items: flex-start;
    }

    .select-input {
      width: 100%;
    }
  }


</style>
